import React from 'react';

const MedicalAid = () => {
  return (
    <main>
      <h1>Medical Aid</h1>
    </main>
  );
};

export default MedicalAid;
