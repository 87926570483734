import React from 'react';

const About = () => {
  return (
    <main>
      <div className='center'>
        <h1>About Us</h1>
      </div>
      <p>So any donations whether its a dollar or a thousand dollars contributes to the growth and development of people from communities that are in need and your generosity allows them to unlock their potentials and get an opportunity to live a better life.</p>
    </main>
  );
};

export default About;
