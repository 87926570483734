import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../data/img/footer/hut.png';
const Navbar = () => {
  return (
    <header>
      <div className='navbar-fixed'>
        <nav>
          <div className='nav-wrapper teal darken-2'>
            <Link to='/' className='brand-logo hide-on-small-only'>
              <div className='col l6 s12'>
              <img
                src={logo}
                alt='logo'
                width='80px'
                height = "60px"
              ></img>
              </div>
            </Link>

            <Link to='/' data-target='mobile-demo' className='sidenav-trigger'>
              <i className='material-icons'>menu</i>
            </Link>
            <ul id='nav-mobile' className='right hide-on-med-and-down'>
            <li>
                <Link to='/'>Home</Link>
              </li>
              <li>
                <Link to='/about'>About</Link>
              </li>
              <li>
                <Link to='/education'>Education</Link>
              </li>
              <li>
                <Link to='/foodbank'>Food Bank</Link>
              </li>
              <li>
                <Link to='/socialwelfare'>Social Welfare</Link>
              </li>
              <li>
                <Link to='/water'>Water</Link>
              </li>
              <li>
                <Link to='/medicalaid'>Medical Aid</Link>
              </li>
              <li>
                <Link to='/orphanages'>Orphanages</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <ul className='sidenav ' id='mobile-demo'>
        <li>
          <Link to='/about'>About</Link>
        </li>
        <li>
          <Link to='/education'>Education</Link>
        </li>
        <li>
          <Link to='/foodbank'>Food Bank</Link>
        </li>
        <li>
          <Link to='/socialwelfare'>Social Welfare</Link>
        </li>
        <li>
          <Link to='/water'>Water</Link>
        </li>
        <li>
          <Link to='/medicalaid'>Medical Aid</Link>
        </li>
        <li>
          <Link to='/orphanages'>Orphanages</Link>
        </li>
      </ul>
    </header>
  );
};

export default Navbar;
