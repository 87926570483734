import React from 'react';

const Orphanages = () => {
  return (
    <main>
      <h1>Orphanages</h1>
    </main>
  );
};

export default Orphanages;
