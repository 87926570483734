import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../data/img/footer/hut.png';
// Need to added correct .svg file
// Add sticky footer
// Add IG and Contribute button

const Footer = () => {
  return (
    <footer>
      <div className='page-footer teal darken-3'>
        <div className='container center'>
          <div className='row'>
            <div className='col l6 s12'>
              <img
                src={logo}
                alt='logo'
                width='200px'
              ></img>
              <p className='grey-text text-lighten-4'></p>
            </div>
            <div className='col l4 offset-l2 s12'>
              <h5 className='white-text font-serif'>Links</h5>
              <ul>
                <li>
                  <Link className='grey-text font-serif text-lighten-3' to='/about'>
                    About
                  </Link>
                </li>
                <li>
                  <Link className='grey-text text-lighten-3' to='/message'>
                    Message
                  </Link>
                </li>
                <li>
                  <Link
                    className='font-text grey-text text-lighten-3 large-text'
                    to='/education'
                  >
                    Education
                  </Link>
                </li>
                <li>
                  <Link
                    className='grey-text text-lighten-3 large-text'
                    to='/foodbank'
                  >
                    Food Bank
                  </Link>
                </li>
                <li>
                  <Link
                    className='grey-text text-lighten-3'
                    to='/socialwelfare'
                  >
                    Social Welfare
                  </Link>
                </li>
                <li>
                  <Link className='grey-text text-lighten-3' to='/water'>
                    Water
                  </Link>
                </li>
                <li>
                  <Link
                    className='grey-text text-lighten-3'
                    to='/medicalaid'
                  >
                    Medical Aid
                  </Link>
                </li>
                <li>
                  <Link className='grey-text text-lighten-3' to='/orphanages'>
                    Orphanages
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='footer-copyright black'>
          <div className='container'>
            <div className='row'>
              <div className='col s6'>
                <br />© 2022 The HUT Foundation
                reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
