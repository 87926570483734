import React from 'react';

const Water = () => {
  return (
    <main>
      <h1>Water</h1>
    </main>
  );
};

export default Water;
