import React from 'react';
import { Link } from 'react-router-dom';

import CampaignModel from './CampaignModal';
const Campaign = () => {
  return (
    <div className='teal darken-3 center white-text'>
        <br />
        <h3>The HUT Foundation</h3>
      <div className='container left-align'>
        <p>
        HUT Foundation is a 501(c)(3) Non-Profit Charity Organization which works in the following fields:
        <li>Education for the poor and needy</li>
        <li>Supporting Orphans and Orphanages</li>
        <li>Supporting Community Centers</li>
        <li>Providing poor and needy families with any medical help</li>
        </p>

        <div className='container center'>
          <Link
            className='waves-effect waves-light pulse btn-large modal-trigger hide-on-small-only'
            to='/donateprop'
          >
            <i className='material-icons right'>donate</i>
          </Link>
          <Link
            className='waves-effect waves-light pulse btn-small modal-trigger hide-on-med-and-up'
            to='/donateprop'
          >
            <i className='material-icons'>donate</i>
          </Link>
          <CampaignModel />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default Campaign;
