import React from 'react';

import Banner from '../layouts/Banner';
import AidTracker from '../layouts/AidTracker';
import Campaign from '../layouts/Campaign';
import CardBlock from '../layouts/CardBlock';
import Quote from '../layouts/Quote';
// import img from "../../data/img/whatwedo/hands.png"
// import img from "../../data/img/whatwedo/hands2.png"
import img from "../../data/img/whatwedo/newbg.png"

import images from "../pages/images.js";
import ImageCarousel from "../pages/ImageCarousel.js";

const Home = () => {
  return (
    <main>
      <div className='col s1'>
        <ImageCarousel images={images} />
      </div>
      <br />
      <Campaign />
      <AidTracker />
      <CardBlock />
      <Quote />
    </main>
  );
};

export default Home;
