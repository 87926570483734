import React from 'react';
import CardBlockItem from './CardBlockItem.js';

import Education from '../../data/img/whatwedo/school.jpg';
import Food from '../../data/img/whatwedo/food1.jpg';
import SocialWelfare from '../../data/img/whatwedo/education.jpg';
import Water from '../../data/img/whatwedo/water.jpg';
import MedicalAid from '../../data/img/whatwedo/medical.jpg';
import Orphanages from '../../data/img/whatwedo/heart.jpg';

const CardBlock = () => {
  return (
    <div className='container'>
      <h1 className='center'>Our Work</h1>
      <br />
      <div className='row'>
        <CardBlockItem
          card='card medium red darken-2 hoverable'
          button='waves-effect waves-light red lighten-1 btn-small'
          title='Education'
          image={Education}
          html='/education'
          content='The HUT foundation has a mission to enlighten the bright minds of children in need with access to education.'
        />
        <CardBlockItem
          card='card medium blue darken-4 hoverable'
          button='waves-effect waves-light blue lighten-1 btn-small'
          title='Food Banks'
          image={Food}
          html='/foodbank'
          content='One of our main goals is to provide necessary nutitious food to people in need. To do that we need any assistance from people like you, whether if it is a 1$ donation or providing food to share within poor neighborhoods.'
        />
        <CardBlockItem
          card='card medium teal darken-2 hoverable'
          button='waves-effect waves-light teal lighten-1 btn-small'
          title='Social Welfare'
          image={SocialWelfare}
          html='/socialwelfare'
          content='Our recent and ongoing projects include building wells and providing clean drinking water in poor communities across the globe. We envision an initation of a water supply system to ensure that clean drinking water is being provided to poor communities around the world'
        />
      </div>
      <div className='row'>
        <CardBlockItem
          card='card medium teal darken-2 hoverable'
          button='waves-effect waves-light blue lighten-1 btn-small'
          title='Water'
          image={Water}
          html='/water'
          content=''
        />
        <CardBlockItem
          card='card medium green darken-2 hoverable'
          button='waves-effect waves-light dark-green lighten-1 btn-small'
          title='Medical Aid'
          image={MedicalAid}
          html='/medicalaid'
          content=''
        />
        <CardBlockItem
          card='card medium orange darken-4 hoverable'
          button='waves-effect waves-light orange lighten-1 btn-small'
          title='Orphanages'
          image={Orphanages}
          html='/orphanages'
          content=''
        />
      </div>
    </div>
  );
};

export default CardBlock;
