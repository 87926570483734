import React from 'react';
import AidTrackerItem from './AidTrackerItem';
const AidTracker = () => {
  return (
    <div className='container center'>
      <h1>Our Goals</h1>
      <br />
      <div className='row'>
        <AidTrackerItem icon='Build' title='Schools' count='' />
        <AidTrackerItem icon='Provide' title='Access to Education' count='' />
        <AidTrackerItem icon='Support' title='People in Need' count='' />
        <AidTrackerItem icon='Foster' title='A Sense of Unity' count='' />
      </div>
      <div className='row'>
      </div>
    </div>
  );
};

export default AidTracker;
