import React from 'react';

const FoodBank = () => {
  return (
    <main>
      <h1>Food Bank</h1>
      <p>Our aim to build a system we we could source our funding to fight hunger with distribution centers to provide food for the needy. To make this possible, we envision to build food banks and community centers that allows us to reach as many poverty-stricken communities to cover as possible. Food is one of the most important basic needs for humans and tackling hunger is something that we as responsible citizens have to partake. That is why we need to provide our best efforts to help vulnerable people in order for them to survive and thrive.</p>
    </main>
  );
};

export default FoodBank;
