import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../../data/img/whatwedo/bg2.png"
import img3 from "../../data/img/whatwedo/newbg.png"
import img5 from "../../data/img/whatwedo/bg5.png"
import img6 from "../../data/img/whatwedo/bg6.png"

export default function ImageCarousel() {
  return (
    <div>
      <Slider dots={true} infinite={true} speed={1500} slidesToScroll={1} arrows={true} slidesToShow={1} autoplay={true} autoplaySpeed={100}>
        <img src={img1} width="100%" />
        <img src={img3} width="100%" />
        <img src={img5} width="80%" height = "750px"/>
        <img src={img6} width="80%" height = "750px"/>
      </Slider>
    </div>
  );
}

