import React from 'react';

const Education = () => {
  return (
    <main>
      <h2>Education</h2>
      <p>We are on a mission to make further education an option for anyone and everyone from disadvantaged communities. With enough funding, we will be able to establish programs and opportunities that help encourage the interests of destitute students to navigate the educational obstacles they so commonly face and help them nurture their skills in the field they show passion.
</p>
    </main>
  );
};

export default Education;
