import img1 from "../../data/img/whatwedo/bg2.png"
import img3 from "../../data/img/whatwedo/newbg.png"
import img5 from "../../data/img/whatwedo/bg5.png"
import img6 from "../../data/img/whatwedo/bg6.png"

const images = [
    {
      id: 1,
      src:{img1},
    },
    {
      id: 3,
      src: {img3},
    },
    {
      id: 5,
      src: {img5},
    },
    {
      id: 6,
      src: {img6},
    }

  ];
  export default images;