import React from 'react';
import QuoteItem from './QuoteItem';

const Quote = () => {
  return (
    <div className='container center'>
      <h1 className="max-w-sm bg-white">Why We Care</h1>
      <br />
      <QuoteItem red='The poorest 40 percent of the world’s population' text=' accounts for 5 percent of global income. The richest 20 percent accounts for three-quarters of world income.' />

       
      <QuoteItem red='In 2020, there were 37.2 million' text=' people in poverty in the U.S., approximately 3.3 million more than in 2019.' />
      <QuoteItem
        red='Poverty rates for people under the age of 18'
        text=' increased from 14.4% in 2019 to 16.1% in 2020.'
      />
      <QuoteItem
        red='10.5 percent (13.8 million)'
        text=' of U.S. households were food insecure at some time during 2020.'
      />
      <QuoteItem red='In 2020, 28.0 million,' text=' did not have health insurance at any point during the year.' />

      <QuoteItem
        red='Four billion people,'
        text="almost 2/3rds of the world's population, experience severe water scarcity for at least one month each year"
      />
      <QuoteItem
        red='According to UNICEF, 22,000 children'
        text=' die each day due to poverty.'
      />
       
    </div>
  );
};

export default Quote;
